import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  /*const toggleMenu = () => {
    setIsOpen(!isOpen);
  };*/

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`App-header ${isScrolled ? "scrolled" : ""}`}>
      <Link to="/" className = "">
        <h1 className="logo">AIdea</h1>
      </Link>
      <a href="mailto:aideagoesup@gmail.com" className="button-outline">Contact</a>
    </header>
  );
}

export default Header;
