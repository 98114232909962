// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2>AIdea</h2>
          <Link to="/privacy-policy" className = "link-privacypolicy"><h5>プライバシーポリシー</h5></Link>
          <a href="mailto:aideagoesup@gmail.com" className="link-privacypolicy">aideagoesup@gmail.com</a>
          <p>&copy; 2024 AIdea. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
