// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Main from './Main';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop here */}
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />  {/* Main content on home path */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Privacy Policy page */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
