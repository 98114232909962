import React, { useState } from 'react';
import '../styles/Team.css';

function Team() {
  const [hoveredMemberId, setHoveredMemberId] = useState(null);

  const members = [
    {
      id: 1,
      name: 'Seiya Moro',
      role: '超偉い',
      description: 'ほぼムーミン',
      imageUrl: 'https://fusessei.s3.ap-northeast-1.amazonaws.com/syukkin_morosan.png',
      //backgroundImageUrl: 'https://cache.moomin.co.jp/storage/characters//hattifatteners.svg', // 背景画像のパスを追加
    },
    {
      id: 2,
      name: 'Jane Smith',
      role: '偉い',
      description: '若干ムーミン',
      imageUrl: 'https://fusessei.s3.ap-northeast-1.amazonaws.com/syukkin_morosan.png',
      //backgroundImageUrl: 'https://cache.moomin.co.jp/storage/characters//mymble.svg', // 背景画像のパスを追加
    },
    {
      id: 3,
      name: 'Alice Brown',
      role: 'そこそこ偉い',
      description: 'ムーミンのなりそこね',
      imageUrl: 'https://fusessei.s3.ap-northeast-1.amazonaws.com/syukkin_morosan.png',
      //backgroundImageUrl: 'https://cache.moomin.co.jp/storage/characters//moominmamma.svg', // 背景画像のパスを追加
    },
  ];

  return (
    <section id="team" className="team-section">
    </section>
  );
}

export default Team;

/*
import React, { useState } from 'react';
import '../styles/Team.css';

function Team() {
  const [hoveredMemberId, setHoveredMemberId] = useState(null);

  const members = [
    {
      id: 1,
      name: 'Seiya Moro',
      role: '超偉い',
      description: 'ほぼムーミン',
      imageUrl: 'https://fusessei.s3.ap-northeast-1.amazonaws.com/syukkin_morosan.png',
      //backgroundImageUrl: 'https://cache.moomin.co.jp/storage/characters//hattifatteners.svg', // 背景画像のパスを追加
    },
    {
      id: 2,
      name: 'Jane Smith',
      role: '偉い',
      description: '若干ムーミン',
      imageUrl: 'https://fusessei.s3.ap-northeast-1.amazonaws.com/syukkin_morosan.png',
      //backgroundImageUrl: 'https://cache.moomin.co.jp/storage/characters//mymble.svg', // 背景画像のパスを追加
    },
    {
      id: 3,
      name: 'Alice Brown',
      role: 'そこそこ偉い',
      description: 'ムーミンのなりそこね',
      imageUrl: 'https://fusessei.s3.ap-northeast-1.amazonaws.com/syukkin_morosan.png',
      //backgroundImageUrl: 'https://cache.moomin.co.jp/storage/characters//moominmamma.svg', // 背景画像のパスを追加
    },
  ];

  return (
    <section id="team" className="team-section">
      
      <h2 className="team-title">Our Team</h2>
      <div className="team-grid">
        {members.map((member) => (
          <div
            key={member.id}
            className="member-card"
            style={{
              backgroundImage: hoveredMemberId === member.id ? `url(${member.backgroundImageUrl})` : 'none',
            }}
            onMouseEnter={() => setHoveredMemberId(member.id)}
            onMouseLeave={() => setHoveredMemberId(null)}
          >
            <img src={member.imageUrl} alt={member.name} className="member-image" />
            <h3 className="member-name">{member.name}</h3>
            <p className="member-role">{member.role}</p>
            <p className="member-description">{member.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Team;
*/