// Main.js
import React from 'react';
import './Main.css';
import About from './components/About';
import Project from './components/Project';
import Team from './components/Team';
/*import Contact from './components/Contact';*/

function Main() {
  return (
    <main className="App-main">
      <About />
      <Project />
      <Team />
    </main>
  );
}

export default Main;
