// Product.js
import React from 'react';
import '../styles/Project.css';

function Project() {
  const projects = [
    {
      id: 1,
      title: 'シュッキン',
      description: 'チーム活動管理アプリ',
      imageUrl: 'https://aidea-hp.s3.ap-northeast-1.amazonaws.com/AIdea%E7%B4%BA.png', // 実際の画像パスをここに
      tags: ['チーム活動'],
    },
    {
      id: 2,
      title: '準備中',
      description: '準備中',
      imageUrl: '準備中', // 実際の画像パスをここに
      tags: ['もうちょい待って'],
    },
  ];

  return (
    <section id="project" className="project-section">
      <div className="project-content">
        <div className="project-intro">
          <h2 className="project-title">Our Projects</h2>
          <h3></h3>
        </div>
        <div className="project-grid">
          {projects.map((project) => (
            <div key={project.id} className="project-card">
              <img src={project.imageUrl} alt={project.title} className="project-image" />
              <h3 className="project-name">{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <div className="project-tags">
                {project.tags.map((tag, index) => (
                  <div key={index} className="project-tag">{tag}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project;

/*
// Product.js
import React from 'react';
import '../styles/Project.css';

function Project() {
  const projects = [
    {
      id: 1,
      title: 'シュッキン',
      description: 'チーム活動管理アプリ',
      imageUrl: '..//assets/AIdea_dark.png', // 実際の画像パスをここに
      tags: ['チーム活動'],
    },
    {
      id: 2,
      title: '準備中',
      description: '準備中',
      imageUrl: '準備中', // 実際の画像パスをここに
      tags: ['もうちょい待って'],
    },
  ];

  return (
    <section id="project" className="project-section">
      <div className="project-content">
        <div className="project-intro">
          <h2 className="project-title">Our Projects</h2>
          <h3>コレコレはこれこれですね</h3>
          <a href="#contact" className="button-outline-product">もっと見る</a>
        </div>
        <div className="project-grid">
          {projects.map((project) => (
            <div key={project.id} className="project-card">
              <img src={project.imageUrl} alt={project.title} className="project-image" />
              <h3 className="project-name">{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <div className="project-tags">
                {project.tags.map((tag, index) => (
                  <button key={index} className="project-tag">{tag}</button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project;
*/