// About.js
import React from 'react';
import Spline from '@splinetool/react-spline';
import '../styles/About.css';
/*import Typewriter from 'typewriter-effect';*/
/*import AIdeaText from './AIdeaText';*/

function About() {
  return (
    <section id="about" className="about-section section-padding">
      <Spline className="background-spline" scene="https://prod.spline.design/bKwcznXyS7Q9a8AU/scene.splinecode" />
      <div className="about-container">
        <div className="about-content text-center">
          <div className="about-header">
            <p className="about-description">
            </p>
          </div>
          <div className="about-button">
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
