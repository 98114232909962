// src/pages/PrivacyPolicy.js
import React from 'react';
import '../styles/PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy applies to the shukkin app (hereby referred to as "Application") for mobile devices that was created by Takuma Kano (hereby referred to as "Service Provider") as a Freemium service. This service is intended for use "AS IS".
      </p>
      
      <h2>Information Collection and Use</h2>
      <p>
        The Application collects information when you download and use it. This information may include information such as:
      </p>
      <ul>
        <li>Your device's Internet Protocol address (e.g. IP address)</li>
        <li>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
        <li>The time spent on the Application</li>
        <li>The operating system you use on your mobile device</li>
      </ul>
      
      <p>
        The Application does not gather precise information about the location of your mobile device.
      </p>
      
      <p>
        The Service Provider may use the information you provided to contact you from time to time to provide you with important information, required notices and marketing promotions.
      </p>
      
      <h2>Third Party Access</h2>
      <p>
        Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.
      </p>
      
      <p>
        The Application utilizes third-party services with their own Privacy Policies. Below are the links to the Privacy Policy of the third-party service providers used by the Application:
      </p>
      <ul>
        <li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
      </ul>
      
      <h2>Opt-Out Rights</h2>
      <p>
        You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes available as part of your mobile device or via the application marketplace.
      </p>

      <h2>Data Retention Policy</h2>
      <p>
        The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete your data, please contact aideagoesup@gmail.com.
      </p>

      <h2>Children</h2>
      <p>
        The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.
      </p>

      <h2>Security</h2>
      <p>
        The Service Provider is concerned about safeguarding the confidentiality of your information. They provide physical, electronic, and procedural safeguards to protect information processed and maintained.
      </p>

      <h2>Changes</h2>
      <p>
        This Privacy Policy may be updated from time to time. The Service Provider will notify you of any changes by updating this page. This policy is effective as of 2024-10-27.
      </p>

      <h2>Your Consent</h2>
      <p>
        By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact the Service Provider at aideagoesup@gmail.com.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
